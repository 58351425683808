<template>
  <div>
    <!-- SPLIDE -->

    <div class="shadow-inner shadow-2xl z-20">
      <splide :slides="mainSliderSlides" :options="mainSliderOptions">
        <splide-slide
          v-for="(slide, i) in mainSliderSlides"
          :key="i"
          class="splide__slide w-full h-screen bg-home relative"
          style="background-size: cover"
          :style="{
            backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, .3), rgba(0, 0, 0, .3)), url(${$api}/uploads/${slide.image})`,
          }"
        >
          <!--  -->
          <div class="text-center pt-20">
            <p
              style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
              class="uppercase text-white drop-shadow-sm pb-8 mt-40 tracking-loose w-full z-50"
            >
              {{ slide.smallCaption }}
            </p>
            <h1
              style="text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3)"
              class="my-4 text-center mx-auto lg:text-3xl text-2xl lg:m-0 m-8 pb-2 drop-shadow-lg text-white font-bold leading-tight z-50"
            >
              {{ slide.caption }}
            </h1>
          </div>

          <div
            style="font-family: sans"
            class="absolute uppercase bottom-0 left-0 right-0 text-white text-xs text-center pb-8"
          >
            <svg
              class="text-center mx-auto opacity-100 animate-bounce"
              xmlns="http://www.w3.org/2000/svg"
              width="30"
              height="30"
              viewBox="0 0 24 24"
              fill="none"
              stroke="#ffffff"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            >
              <path d="M6 9l6 6 6-6" />
            </svg>

            <span class="opacity-70"> Scroll Down </span>
          </div>
        </splide-slide>
      </splide>
    </div>

    <!-- SPLIDE // -->

    <!-- SPLASH -->
    <!-- <div class="bg-home relative mx-auto">
      <div
        style="font-family: sans;"
        class="absolute uppercase bottom-0 left-0 right-0 text-white text-xs text-center  pb-8"
      >
        <svg
          class="text-center mx-auto opacity-100 animate-bounce"
          xmlns="http://www.w3.org/2000/svg"
          width="30"
          height="30"
          viewBox="0 0 24 24"
          fill="none"
          stroke="#ffffff"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M6 9l6 6 6-6" />
        </svg>

        <span class="opacity-70"> Scroll Down </span>
      </div>
    </div> -->
    <!-- /SPLASH -->
    <!-- FAVORITE PROPERTIES -->
    <div class="container mx-auto bg-white mt-12 md:mt-18 mb-20">
      <div class="text-center text-highlight text-5xl mt-10 py-3 mb-6">
        Our Favorite Properties
      </div>

      <div class="max-w-6xl mx-auto p-4 md:p-0">
        <section class="py-2 pt-8">
          <LinksGrid :properties="featuredProperties"> </LinksGrid>
        </section>
      </div>
    </div>
    <!-- / FAVORITE PROPERTIES -->
    <div class="max-w-6xl mx-auto mb-20">
      <div class="text-center md:px-0 px-4 text-highlight text-4xl">
        Featured Special Offers
      </div>
      <div
        class="
          text-center
          px-8
          md:px-48
          py-4
          mb-8
          text-gray-700
          leading-8
          text-sm
        "
      ></div>
      <SpecialOfferCard
        v-for="(offer, i) in featuredPackages"
        :key="i"
        :specialOffer="offer"
      ></SpecialOfferCard>
    </div>
  </div>
</template>

<script>
import LinksGrid from '@/components/layout/LinksGrid';
import SpecialOfferCard from '@/components/layout/SpecialOfferCard';
export default {
  name: 'Home',
  data() {
    return {
      mainSliderOptions: {
        clones: 3,
        pagination: false,
        arrows: false,
        autoplay: true,
        interval: 4000,
      },
      featuredProperties: [],
      featuredPackages: [],
      specialOffers: [
        {
          name: 'Summer Escape Balinese Special Massage',
          property: 'Niyama Private Islands',
          bookBefore: '19 August 2022',
          travelBefore: '20 December 2022',
        },
        {
          name: 'Summer Escape Balinese Special Massage',
          property: 'Niyama Private Islands',
          bookBefore: '19 August 2022',
          travelBefore: '20 December 2022',
        },
        {
          name: 'Summer Escape Balinese Special Massage',
          property: 'Niyama Private Islands',
          bookBefore: '19 August 2022',
          travelBefore: '20 December 2022',
        },
        {
          name: 'Summer Escape Balinese Special Massage',
          property: 'Niyama Private Islands',
          bookBefore: '19 August 2022',
          travelBefore: '20 December 2022',
        },
        {
          name: 'Summer Escape Balinese Special Massage',
          property: 'Niyama Private Islands',
          bookBefore: '19 August 2022',
          travelBefore: '20 December 2022',
        },
      ],
    };
  },
  methods: {
    async getFeaturedProperties() {
      try {
        const res = await this.$axios.get(`${this.$api_v1}/featuredProperties`);

        if (res.status === 200) {
          if (res.data.featuredProperties.length > 7) {
            this.featuredProperties = res.data.featuredProperties
              .splice(0, 7)
              .map((p) => p.property);
          } else {
            this.featuredProperties = res.data.featuredProperties.map(
              (p) => p.property
            );
          }
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
    async getFeaturedPackages() {
      try {
        const res = await this.$axios.get(`${this.$api_v1}/featuredPackages`);

        if (res.status === 200) {
          this.featuredPackages = res.data.featuredPackages.map(
            (p) => p.package
          );
        }
      } catch (e) {
        this.$errorHandler(e);
      }
    },
  },
  mounted() {
    this.getFeaturedProperties();
    this.getFeaturedPackages();
  },
  computed: {
    mainSliderSlides() {
      if (!this.featuredProperties || !this.featuredProperties.length)
        return [];

      let arr = this.featuredProperties.map((p) => {
        if (p.homeSliderImage && p.homeSliderImage.image) {
          return p.homeSliderImage;
        } else {
          return null;
        }
      });

      arr = arr.filter((item) => item);

      console.log(arr);
      return arr;
    },
  },
  components: {
    LinksGrid,
    SpecialOfferCard,
  },
};
</script>

<style lang="scss" scoped>
.bg-home {
  height: 620px;
  background-size: cover;
  background-position: center;
  transition: transform 0.2s;
  overflow: hidden;
  box-shadow: inset 0 0 0 2000px rgba(17, 17, 17, 0.4);
}

* {
  font-family: 'Brown Regular';
  font-weight: 200;
  font-style: normal;
  font-display: auto;
  unicode-range: U+000-5FF;
  src: local('Brown Regular'), url('/assets/Brown-Regular.ttf') format('ttf'),
    url('/assets/Brown-Regular.ttf') format('ttf');
}

@font-face {
  font-family: 'Brown Regular';
  src: url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot');
  src: url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.eot?#iefix')
      format('embedded-opentype'),
    url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff2')
      format('woff2'),
    url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.woff')
      format('woff'),
    url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.ttf')
      format('truetype'),
    url('//db.onlinewebfonts.com/t/123c7e674188c97798dd39cd41ff2d85.svg#Brown Regular')
      format('svg');
}
</style>
